import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import VCardGenerator from './components/VCardGenerator';
import QRCodeGenerator from './components/QRCodeGenerator';
import LandingPage from './components/LandingPage'; // Import LandingPage

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/vcard" element={<VCardGenerator />} />
        <Route path="/qrcode" element={<QRCodeGenerator />} />
      </Routes>
    </Router>
  );
}

export default App;
