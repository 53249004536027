import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header'; // Import the Header
import styles from './QRCodeGenerator.module.css'; // Import the CSS module

const QRCodeGenerator = () => {
  const [qrType, setQrType] = useState('website'); // Default to website
  const [formData, setFormData] = useState({
    website: '',
    address: '',
    wifiName: '',
    wifiPassword: '',
    customText: '',
  });

  const [qrCodeDataURL, setQrCodeDataURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleTypeChange = (e) => {
    setQrType(e.target.value);
    setFormData({
      website: '',
      address: '',
      wifiName: '',
      wifiPassword: '',
      customText: '',
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const generateQRCode = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.post('http://localhost:5001/generate', { qrType, formData }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setQrCodeDataURL(response.data.qrCodeDataURL);
    } catch (err) {
      console.error('Error generating QR Code:', err);
      setError('Failed to generate QR Code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generateQRCode();
  };

  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCodeDataURL;
    link.download = `QR_Code.png`;
    link.click();
  };

  return (
    <>
      <Header /> {/* Use the Header component */}
      <div className={styles.container}>
        <h1 className={styles.h1}>Create a QR Code</h1>
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="qrType">Select QR Code Type:</label>
            <select id="qrType" value={qrType} onChange={handleTypeChange}>
              <option value="website">Website URL</option>
              <option value="address">Address</option>
              <option value="wifi">Wi-Fi</option>
              <option value="customText">Custom Text</option>
            </select>
          </div>

          {qrType === 'website' && (
            <input
              type="url"
              name="website"
              placeholder="Website URL"
              value={formData.website}
              onChange={handleChange}
              required
            />
          )}

          {qrType === 'address' && (
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          )}

          {qrType === 'wifi' && (
            <>
              <input
                type="text"
                name="wifiName"
                placeholder="Wi-Fi Network Name"
                value={formData.wifiName}
                onChange={handleChange}
                required
              />
              <input
                type="password"
                name="wifiPassword"
                placeholder="Wi-Fi Password"
                value={formData.wifiPassword}
                onChange={handleChange}
                required
              />
            </>
          )}

          {qrType === 'customText' && (
            <textarea
              name="customText"
              placeholder="Enter your custom text"
              value={formData.customText}
              onChange={handleChange}
              required
            />
          )}

          <button type="submit" disabled={loading}>
            {loading ? 'Generating...' : 'Generate QR Code'}
          </button>
        </form>
        {error && <p className={styles.error}>{error}</p>}

        {qrCodeDataURL && (
          <div className={styles.qrCodeContainer}>
            <h2>Your QR Code</h2>
            <img src={qrCodeDataURL} alt="QR Code" />
            <button onClick={downloadQRCode}>Download QR Code</button>
          </div>
        )}
      </div>
    </>
  );
};

export default QRCodeGenerator;
