import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header'; // Importing the Header component
import './SignUp.css';

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });

  const { name, email, phone, password } = formData;
  const navigate = useNavigate();

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, formData);
      localStorage.setItem('token', res.data.token); // Store JWT token
      navigate('/'); // Redirect to home page
    } catch (error) {
      console.error('Sign-up failed:', error);
    }
  };

  return (
    <div>
      {/* Header Section */}
      <Header /> {/* Adding the header */}
      
      {/* Sign-Up Form */}
      <div className="sign-up-container">
        <h1>Sign Up</h1>
        <form onSubmit={onSubmit} className="sign-up-form">
          <input
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            placeholder="Full Name"
            required
          />
          <input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            placeholder="Email"
            required
          />
          <input
            type="tel"
            name="phone"
            value={phone}
            onChange={onChange}
            placeholder="Phone Number"
            required
          />
          <input
            type="password"
            name="password"
            value={password}
            onChange={onChange}
            placeholder="Password"
            required
          />
          <button type="submit" className="btn-submit">Sign Up</button>
        </form>
        <p className="sign-in-link">
          Already have an account? <Link to="/signin">Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
