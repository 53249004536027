import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Header from './Header'; // Import the Header
import './VCardGenerator.css';

const VCardGenerator = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    jobTitle: '',
    company: '',
    phone: '',
    email: '',
    website: '',
    address: '',
    photo: null,
    photoPreview: '',
  });

  const [qrCodeDataURL, setQrCodeDataURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State to show/hide the popup

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFormData({
        ...formData,
        photo: acceptedFiles[0],
        photoPreview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
  });

  const generateVCard = async () => {
    if (!formData.phone) {
      setError('Phone number is required.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('fullName', formData.fullName);
    formDataToSend.append('jobTitle', formData.jobTitle);
    formDataToSend.append('company', formData.company);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('email', formData.email); // Optional email
    formDataToSend.append('website', formData.website);
    formDataToSend.append('address', formData.address);
    if (formData.photo) formDataToSend.append('photo', formData.photo);

    try {
      setLoading(true);
      setError(null);

      const response = await axios.post('http://localhost:5001/generate', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setQrCodeDataURL(response.data.qrCodeDataURL);
      setShowPopup(true); // Show popup when QR code is generated
    } catch (err) {
      console.error('Error generating QR Code:', err);
      setError('Failed to generate QR Code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    generateVCard();
  };

  const downloadQRCode = () => {
    const link = document.createElement('a');
    link.href = qrCodeDataURL;
    link.download = `${formData.fullName}_vCard_QR.png`;
    link.click();
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Header /> {/* Use the Header component */}
      <div className="container">
        <h1>Add Info to Generate QR VCard</h1>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="photo-upload">
            <div {...getRootProps()} className="photo-box">
              <input {...getInputProps()} />
              {formData.photoPreview ? (
                <img src={formData.photoPreview} alt="Profile" className="photo-preview" />
              ) : (
                <p>+ Add Photo</p>
              )}
            </div>
          </div>

          <input
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="jobTitle"
            placeholder="Job Title"
            value={formData.jobTitle}
            onChange={handleChange}
          />
          <input
            type="text"
            name="company"
            placeholder="Company"
            value={formData.company}
            onChange={handleChange}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone Number (Required)"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address (Optional)"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="url"
            name="website"
            placeholder="Website URL"
            value={formData.website}
            onChange={handleChange}
          />
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
          />

          <button type="submit" disabled={loading}>
            {loading ? 'Generating...' : 'Generate QR Code'}
          </button>
        </form>
        {error && <p className="error">{error}</p>}

        {qrCodeDataURL && (
          <>
            {showPopup && (
              <div className="popup">
                <div className="popup-content">
                  <h2>Your QR Code</h2>
                  <img src={qrCodeDataURL} alt="vCard QR Code" />
                  <button onClick={downloadQRCode}>Download QR Code</button>
                  <button onClick={closePopup}>Close</button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default VCardGenerator;
