import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header'; // Import the Header component

function LandingPage() {
  const navigate = useNavigate();

  const handleGenerateVCard = () => {
    navigate('/vcard');
  };

  const handleGenerateQRCode = () => {
    navigate('/qrcode');
  };

  return (
    <div className="landing-page">
      {/* Include Header at the top */}
      <Header />

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-text">
          <h1>Create Digital Business Cards & Custom QR Codes</h1>
          <p>Generate vCards and various QR codes for business, Wi-Fi, and more in seconds!</p>
          <div className="cta-buttons">
            <button onClick={handleGenerateVCard}>Virtual Card</button>
            <button onClick={handleGenerateQRCode}>QR Code</button>
          </div>
        </div>
        <div className="hero-image">
          <img src="/hero.png" alt="Hero Image" />
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
