import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import Header from './Header'; // Assuming you're using a common Header component
import './SignIn.css'; // Sign-in specific styles

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/signin`, { email, password });
      localStorage.setItem('token', response.data.token);
      
      // Redirect to the intended page
      const redirectTo = location.state?.from || '/';
      navigate(redirectTo);
    } catch (err) {
      console.error('Error signing in:', err);
      alert('Invalid login');
    }
  };

  return (
    <div>
      {/* Header Section */}
      <Header /> {/* If you want a consistent header */}
      
      {/* Sign-In Form */}
      <div className="sign-in-container">
        <h1>Sign In</h1>
        <form onSubmit={handleSubmit} className="sign-in-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit" className="btn-submit">Sign In</button>
        </form>
        <p className="sign-up-link">
          Don’t have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
